<template>
    <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <template v-for="(filter, index) in filterData" :key="index">
                    <div class="py-2" :class="{'border-bottom': index < (filterData.length - 1)}" v-if="!filter.hidden">
                        <div class="d-flex mb-1">
                            <h6 class="w-100">{{filter.name}}</h6>
                            <FormItem :id="'slider-'+index" :class="'float-right'" type="slider" v-model="filter.active" :options="[true,false]" @onchange="emptyFilter(filter)" />
                        </div>
                        <div v-if="filter.checkboxes && filter.active">
                            <div v-for="option in filter.checkboxes" :key="option.id">
                                <FormItem :id="'checkbox-'+index+'-'+option.id" :label="filter.nameField ? option[filter.nameField] : option.name" type="checkbox" v-model="option.checked" />
                            </div>
                        </div>
                        <div v-else-if="filter.options && filter.active">
                            <FormItem :id="'search-'+index" type="select" v-model="filter.value" :options="filter.options" :placeholder="$t('form.select')"
                                      :idField="filter.idField ? filter.idField : 'id'" :nameField="filter.nameField ? filter.nameField : 'name'" />
                        </div>
                        <div v-else-if="filter.service && filter.active">
                            <FormItem :id="'search-'+index" :type="filter.multiple ? 'livesearch-list' : 'livesearch'" v-model="filter.value" :service="filter.service" 
                                      :idField="filter.idField ? filter.idField : 'id'" :nameField="filter.nameField ? filter.nameField : 'name'" @delete="setFilterActivity(filter)" />
                        </div>
                        <div v-else-if="filter.radio && filter.active">
                            <FormItem :id="'radio-'+index" type="radiogroup" v-model="filter.value" :options="filter.radio" />
                        </div>
                        <div v-else-if="filter.active && filter.date && filter.value">
                            <FormItem type="date" :id="'date1-'+index" v-model="filter.value[0]" :label="$t('overview.from')" />
                            <FormItem type="date" :id="'date2-'+index" v-model="filter.value[1]" :label="$t('overview.to')" />
                        </div>
                        <div v-else-if="filter.active && !filter.boolean">
                            <FormItem :id="'text-'+index" type="text" v-model="filter.value" />
                        </div>
                    </div>
                    </template>
                    
                    <div class="d-flex justify-content-between mt-4">
                        <button type="button" class="btn btn-unidark" data-bs-dismiss="modal" @click="removeFilters">Filters verwijderen</button>
                        <button type="button" class="btn btn-unilight" data-bs-dismiss="modal" @click="save">Opslaan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OverviewFilter',
        props: ['filterOptions'],
        data() {
            return {
                initialized: false,
                filterData: []
            }
        },
        methods: {
            onOpen(){
                if(this.initialized) return;
                this.filterData.forEach(filter => {
                    if(filter.service && filter.checkboxes){
                        filter.service.index().then(response => {
                            filter.checkboxes = response.data;
                            filter.checkboxes.forEach(box => box.value = false);
                        }).catch(error => {
                            console.log('error', error)
                        });
                    }
                });
                this.initialized = true;
            },
            setFilterActivity(filter){
                if(filter.value.length == 0) filter.active = false;
            },
            removeFilters(){
                this.filterData.forEach(filter => {
                    filter.active = false;
                    if(filter.options){
                        filter.value = null;
                    }
                    else if (filter.date){
                        filter.value = [null, null];
                    }
                    else if(filter.checkboxes){
                        filter.checkboxes.forEach(box => {
                            box.checked = false;
                        });
                    }
                    else if(filter.service && filter.multiple){
                        filter.value = [];
                    }
                    else if(filter.service){
                        filter.value = null;
                    }
                });
                this.save();
            },
            save(isAutomatic = false) {
                this.$parent.filtersActive = this.filterData.filter(f => f.active).length;
                let returnValue = [];
                let hasValues = false;
                this.filterData.forEach(filter => {
                    if(!filter.active || (!filter.checkboxes && !filter.boolean && filter.value === null)) return;
                    let values = [];
                    const idField = filter.idField ? filter.idField : 'id';
                    if(filter.radio){
                        values = [filter.value];
                    }
                    else if(filter.checkboxes){
                        filter.checkboxes.forEach(box => {
                            if(box.checked){
                                values.push(box[idField]);
                            }
                        });
                    }
                    else if(filter.service){
                        if(filter.multiple){
                            filter.value.forEach(val => {
                                values.push(val[idField]);
                            });
                        }
                        else if(filter.value.length !== 0){
                            values = [filter.value];
                        }
                    }
                    else if(filter.boolean){
                        values = [1];
                    }
                    else{
                        let value = filter.value;
                        if(filter.capitals) value = value.toUpperCase();
                        if(filter.like) value = "LIKE!" + value;
                        values = [value];
                    }
                    if(!hasValues){
                        hasValues = (values.length > 0 && values[0]);
                    }
                    if(filter.hidden){
                        this.$parent.filtersActive--;
                    }
                    returnValue.push({
                        field: filter.field,
                        value: values.length == 1 && !filter.checkboxes ? values[0] : values
                    });
                });
                if(hasValues){
                    this.$parent.getIndexFiltered(returnValue, isAutomatic);
                    sessionStorage.setItem("filter_" + this.$route.name, JSON.stringify(this.filterData));
                }
                else{
                    this.$parent.filtersActive = 0;
                    this.filterData.forEach(filter => {
                        filter.active = false;
                    });
                    this.$parent.getIndexFiltered([], isAutomatic);
                    sessionStorage.removeItem("filter_" + this.$route.name);
                }
            },
            emptyFilter(filter){
                if(!filter.active){
                    if(filter.checkboxes){
                        for(let option in filter.checkboxes) filter.checkboxes[option].checked = false;
                    }
                    else if (filter.date){
                        filter.value = [null, null];
                    }
                    else{
                        filter.value = filter.service ? [] : null;
                    }
                }
            },
            setFilters(){
                if(!this.filterOptions) return;
                this.filterData = this.filterOptions;
                let autoFilter = false;
                this.filterData.forEach(filter => {
                    filter.value = filter.service ? [] : null;
                    filter.active = filter.is_on !== undefined ? true : false;
                    if(filter.active){
                        this.$parent.filtersActive++;
                    }
                    if(filter.date){
                        filter.value = [null, null];
                    }
                    if(filter.checkboxes){
                        if(filter.is_on !== undefined){
                            filter.value = [];
                            filter.checkboxes.forEach((box, index) => {
                                box.value = filter.is_on[index];
                                if(filter.is_on[index]){
                                    filter.value.push(box.id);
                                    box.checked = 1;
                                    autoFilter = true;
                                }
                            });
                        }
                        else{
                            filter.checkboxes.forEach(box => {box.value = false;});
                        }
                    }
                });
                if(autoFilter){
                    this.save(true);
                }
            }
        },
        mounted() {
            this.setFilters();
        }
    }

</script>

<style>
#filterModal {
    z-index: 2222
}
#filterModal > div {
    margin: 0 auto !important
}
#filterModal .modal-content {
    top: 130px;
    left: calc(50vw + 250px)
}
#filterModal:not(.show) > div {
    transform: translate(0, 0) !important;
}
#filterModal.show > div {
    transform: translate(-540px, 0) !important;
}
h6{
    padding-top: .66rem;
}
</style>